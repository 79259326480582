<template>
  <div>
    <div
      v-if="data"
      class="top-section"
    >
      <div
        v-for="item in filteredData"
        :key="item.name"
        class="box"
        :style="{ width: userInfo.role == 'EMP' ? '32%' : '23.5%' }"
      >
        <span class="box-header">{{ item.name }} <span class="box-value ml-5">{{ item.count }}</span></span>
        <hr class="hr-line">
        <ul class="box-subheader">
          <li
            v-for="(count, empType) in item.groupedByType"
            :key="empType"
          >
            <span class="header">{{ empType || 'Not Assign' }}</span>
            <span class="count">{{ count.length }}</span>
          </li>
        </ul>
      </div>
    </div>
    <resource-availability-accordion :data="data" />
  </div>
</template>

<script>
import ResourceAvailabilityAccordion from './ResourceAvailabilityAccordion.vue'

export default {
  name: 'AvailbleResources',
  components: { ResourceAvailabilityAccordion },
  props: {
    data: {
      type: [Object, Array],
      required: true,
    },
  },
  data() {
    return {
      items: {},
      perPage: 25,
      currentPage: 1,
    }
  },
  computed: {
    filteredData() {
      return Object.keys(this.data).map(key => ({
        name: key,
        count: this.data[key].count,
        groupedByType: this.groupByEmployeeType(this.data[key].userlist),
      })).filter(data => data.count > 0)
    },
  },
  async mounted() {
    /* if employee list not in store then call employee list api */
    if (
      !this.$store.state.app.employeeTypeList
      || !this.$store.state.app.employeeTypeList.length
    ) {
      await this.employeeTypeList()
    }
  },

  methods: {
    groupByEmployeeType(employees) {
    // Retrieve the employee type list from the store
      const employeeTypeList = [...this.$store.state.app.employeeTypeList, { name: 'None' }]

      // Initialize groups based on employeeTypeList
      const grouped = employeeTypeList.reduce((acc, type) => {
        acc[type.name] = []
        return acc
      }, {})

      // Group employees based on the employeeType
      employees.forEach(employee => {
        const type = employee?.employeeType || 'None'
        if (grouped[type]) {
          grouped[type].push(employee)
        } else {
          grouped.None.push(employee)
        }
      })

      return Object.fromEntries(employeeTypeList.map(type => [type.name, grouped[type.name]]))
    },
  },

}
</script>

<style lang="scss">
.top-section {
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
  margin-top: 1rem;
  color: #2178fb;
  overflow: auto;
  padding: 10px 0px;
}

.box {
  display: inline-table;
  // border: 1px solid #716f6f;
  border-radius: 16px;
  width: 23%;
  text-align: center;
  margin: 0 10px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background-color: white;
  transition: transform 0.2s;
}

.box-header {
  font-size: 1.25rem;
  font-weight: bold;
  color: #2178fb;
  display: block;
  margin-bottom: 10px;
  margin-top: 10px;
}

.box-value {
  font-size: 1.5rem;
  color: #000;
}

ul.box-subheader {
  display: flex;
  justify-content: space-between;
  /* Ensures even distribution */
  width: 100%;
  margin: 1rem 0;
  padding: 10px;
  list-style-type: none;
  gap: 10px;
  flex-wrap: wrap;
}

ul.box-subheader li {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 48%;
}

.header {
  font-size: 18px;
  font-weight: 500;
  color: #2178fb;
  margin-bottom: 0.5rem;
}

.count {
  font-size: 18px;
  font-weight: 500;
  color: #0f0f0f;
}
</style>
